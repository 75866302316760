import admission from './admission';
import appInitialState from './app';
import auth from './auth';
import setting from './setting';
import tab from './tab';

const initialStates = {
  ...appInitialState,
  ...setting,
  ...admission,
  ...auth,
  tab,
};

export default initialStates;
