import React, { Suspense, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import RoutePaths from './config/routes/routePaths';
import './custom.scss';
import './index.css';

const Dashboard = React.lazy(() => import('./containers/Dashboard'));
const PageLoader = React.lazy(
  () => import('./containers/Dashboard/PageLoader')
);
const PageNotFound = React.lazy(
  () => import('./containers/Errors/PageNotFound')
);
const ErrorBoundary = React.lazy(
  () => import('./containers/Errors/ErrorBoundary')
);

function App() {
  useEffect(() => {
    // Prevent Going back to previous page
    window.history.pushState(null, document.title, '/');
    window.addEventListener('popstate', () => {
      // TO view the events, pass event as a prop to the function
      window.history.pushState(null, document.title, '/');
    });
  });

  return (
    <Suspense fallback={<PageLoader />}>
      <ErrorBoundary>
        <Router>
          <Routes>
            <Route
              exact
              path={RoutePaths.dashboard.path}
              element={<Dashboard />}
            />
            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </ErrorBoundary>
    </Suspense>
  );
}

export default App;
