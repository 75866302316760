import { takeLatest, fork, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { runningAdmissionActions } from '../../actions';

function* getAllRunningAdmissions() {
  try {
    const response = yield axios({
      url: '/applicants/running-admissions',
      method: 'GET',
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_SUCCESS,
      data: response.runningAdmissions,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* getSingleRunningAdmission(actions) {
  try {
    const response = yield axios({
      url: `/applicants/running-admissions/${actions.runningAdmissionId}`,
      method: 'GET',
    });
    yield put({
      type: runningAdmissionActions.GET_SELECTED_RUNNING_ADMISSION_SUCCESS,
      data: response.runningAdmission,
    });
    yield put({
      type: runningAdmissionActions.SET_SELECTED_RUNNING_ADMISSION,
      payload: response.runningAdmission,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_SELECTED_RUNNING_ADMISSION_ERROR,
      error: error.data,
    });
  }
}

function* getRunningAdmissionProgrammes(actions) {
  try {
    const response = yield axios({
      url: `/applicants/running-admissions/programmes/${actions.runningAdmissionId}`,
      method: 'GET',
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_PROGRAMMES_SUCCESS,
      data: response.programmes,
      runningAdmissionId: actions.runningAdmissionId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_PROGRAMMES_ERROR,
      error: error.data,
    });
  }
}

function* getRunningAdmissionApplicantContext(actions) {
  try {
    const response = yield axios({
      url: `/applicants/running-admissions-applicants/${actions.runningAdmissionId}`,
      method: 'GET',
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_SUCCESS,
      data: response.runningAdmissionApplicant || {},
      runningAdmissionId: actions.runningAdmissionId,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_ERROR,
      error: error.data,
    });
  }
}

function* submitApplicationForm(actions) {
  try {
    const response = yield axios({
      url: `/applicants/running-admissions-applicants/${actions.formID}`,
      method: 'PUT',
      data: actions.payload,
    });
    yield put({
      type: runningAdmissionActions.SUBMIT_APPLICANT_FORM_SUCCESS,
      data: response.data,
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
      runningAdmissionId: response.data?.running_admission_id,
    });
  } catch (error) {
    yield put({
      type: runningAdmissionActions.SUBMIT_APPLICANT_FORM_ERROR,
      error: error.data,
    });
  }
}

function* watchGetRunningAdmissions() {
  yield takeLatest(
    runningAdmissionActions.GET_RUNNING_ADMISSION_REQUEST,
    getAllRunningAdmissions
  );
}

function* watchSingleRunningAdmission() {
  yield takeLatest(
    runningAdmissionActions.GET_SELECTED_RUNNING_ADMISSION_REQUEST,
    getSingleRunningAdmission
  );
}

function* watchGetRunningAdmissionProgrammes() {
  yield takeEvery(
    runningAdmissionActions.GET_RUNNING_ADMISSION_PROGRAMMES_REQUEST,
    getRunningAdmissionProgrammes
  );
}

function* watchGetRunningAdmissionApplicantContext() {
  yield takeEvery(
    runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
    getRunningAdmissionApplicantContext
  );
}

function* watchSubmitApplicationForm() {
  yield takeLatest(
    runningAdmissionActions.SUBMIT_APPLICANT_FORM_REQUEST,
    submitApplicationForm
  );
}

const forkFunctions = [
  fork(watchGetRunningAdmissions),
  fork(watchSingleRunningAdmission),
  fork(watchGetRunningAdmissionProgrammes),
  fork(watchGetRunningAdmissionApplicantContext),
  fork(watchSubmitApplicationForm),
];

export default forkFunctions;
