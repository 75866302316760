import { combineReducers } from 'redux';
import { authActions } from '../actions';
import metadata from './app/metadata';
import institutionStructure from './app/institutionStructure';
import unebSubject from './app/unebSubject';
import server from './app/server';
import auth from './auth';
import setting from './setting';
import runningAdmission from './admission/runningAdmission';
import myApplicationForm from './admission/myApplicationForm';
import applicationSection from './admission/applicationSection';
import tab from './tab';

const appReducer = combineReducers({
  setting,
  auth,
  metadata,
  institutionStructure,
  unebSubject,
  server,
  tab,
  runningAdmission,
  applicationSection,
  myApplicationForm,
});

const rootReducer = (state, actions) => {
  let newState = state;
  if (actions.type === authActions.LOGOUT_SUCCESS) {
    newState = {};
  }
  return appReducer(newState, actions);
};

export default rootReducer;
