const appInitialState = {
  server: {
    error: {},
    success: {},

    verifyingGoogleToken: false,
    verifyGoogleTokenResponse: {},
    verifyGoogleTokenError: {},
  },
  metadata: {
    metadata: [],
    singleMetadata: {},
    metadataError: {},
    loading: false,
  },
  unebSubject: {
    loading: false,
    loadError: {},
    unebSubjects: [],
  },
  institutionStructure: {
    gettingInstitutionStructure: false,
    institutionStructure: {},
    institutionStructureError: {},
  },
};

export default appInitialState;
