import axios from 'axios';
import { fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  myApplicationFormActions,
  runningAdmissionActions,
} from '../../actions';

function* getAllMyAdmissionForms() {
  try {
    const response = yield axios({
      url: '/applicants/applicant-data/my-forms',
      method: 'GET',
    });
    yield put({
      type: myApplicationFormActions.GET_MY_ADMISSION_FORMS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: myApplicationFormActions.GET_MY_ADMISSION_FORMS_ERROR,
      error: error.data,
    });
  }
}

function* getAllMyApplicationForm(actions) {
  try {
    const response = yield axios({
      url: `/applicants/running-admissions-applicants/applicant-form/${actions.formId}`,
      method: 'GET',
    });
    yield put({
      type: myApplicationFormActions.GET_MY_APPLICATION_FORM_SUCCESS,
      data: response.data,
      formId: actions.formId,
    });
  } catch (error) {
    yield put({
      type: myApplicationFormActions.GET_MY_APPLICATION_FORM_ERROR,
      error: error.data,
    });
  }
}

function* getAllMyAdmissions() {
  try {
    const response = yield axios({
      url: `/applicants/admissions`,
      method: 'GET',
    });
    yield put({
      type: myApplicationFormActions.GET_MY_ADMISSIONS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: myApplicationFormActions.GET_MY_ADMISSIONS_ERROR,
      error: error.data,
    });
  }
}

function* downloadAdmissionLetter(actions) {
  try {
    const { applicationFormId, fromAdmissionStatus } = actions;

    let url = `/applicants/running-admissions-applicants/print-admission-letter/${applicationFormId}`;

    if (fromAdmissionStatus === true) {
      url = `/applicants/admissions/download/${applicationFormId}`;
    }

    const response = yield axios({
      url,
      method: 'GET',
      timeout: 1200000,
      timeoutErrorMessage: 'Request Timed out',
      responseType: 'blob',
    });

    const openInNewTab = (href) => {
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href,
      }).click();
    };

    const file = new Blob([response.data], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);

    openInNewTab(fileURL);
    setTimeout(() => {
      window.URL.revokeObjectURL(file);
    }, 100);

    yield put({
      type: myApplicationFormActions.DOWNLOAD_ADMISSION_LETTER_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: myApplicationFormActions.DOWNLOAD_ADMISSION_LETTER_ERROR,
      error,
    });
  }
}

function* generatePaymentReference(actions) {
  try {
    const { formId } = actions;
    const response = yield axios({
      url: `/applicants/running-admissions-applicants/generate-prn/${formId}`,
      method: 'post',
    });
    yield put({
      type: runningAdmissionActions.GET_RUNNING_ADMISSION_APPLICANT_CONTEXT_REQUEST,
      runningAdmissionId: response?.data?.running_admission_id,
    });
    yield put({
      type: myApplicationFormActions.GENERATE_PAYMENT_REFERENCE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: myApplicationFormActions.GENERATE_PAYMENT_REFERENCE_ERROR,
      error: error.data,
    });
  }
}

function* downloadApplicationForm(actions) {
  try {
    yield axios({
      url: `/applicants/running-admissions-applicants/download-form/${actions.applicationFormId}`,
      method: 'post',
      responseType: 'blob',
    }).then((res) => {
      const newBlob = new Blob([res.data], { type: 'application/pdf' });

      // IE doesn't allow using a blob object directly as link href
      // instead it is necessary to use msSaveOrOpenBlob
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      // For other browsers:
      // Create a link pointing to the ObjectURL containing the blob.
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.target = '_blank';
      link.title = `APPLICATION FORM ${actions.applicationFormId}`;

      link.download = `APPLICATION-FORM-${actions.applicationFormId}.pdf`;
      link.click();
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(data);
      }, 100);
    });
    yield put({
      type: myApplicationFormActions.DOWNLOAD_APPLICATION_FORM_SUCCESS,
    });
  } catch (error) {
    yield put({
      type: myApplicationFormActions.DOWNLOAD_APPLICATION_FORM_ERROR,
      error: error.data,
    });
  }
}

function* watchGetMyAdmissionForms() {
  yield takeLatest(
    myApplicationFormActions.GET_MY_ADMISSION_FORMS_REQUEST,
    getAllMyAdmissionForms
  );
}

function* watchGetMyApplicationForm() {
  yield takeEvery(
    myApplicationFormActions.GET_MY_APPLICATION_FORM_REQUEST,
    getAllMyApplicationForm
  );
}

function* watchGetMyAdmissions() {
  yield takeEvery(
    myApplicationFormActions.GET_MY_ADMISSIONS_REQUEST,
    getAllMyAdmissions
  );
}

function* watchGetGeneratePaymentReference() {
  yield takeLatest(
    myApplicationFormActions.GENERATE_PAYMENT_REFERENCE_REQUEST,
    generatePaymentReference
  );
}

function* watchGetDownloadApplicationForm() {
  yield takeLatest(
    myApplicationFormActions.DOWNLOAD_APPLICATION_FORM_REQUEST,
    downloadApplicationForm
  );
}

function* watchGetDownloadAdmissionLetter() {
  yield takeLatest(
    myApplicationFormActions.DOWNLOAD_ADMISSION_LETTER_REQUEST,
    downloadAdmissionLetter
  );
}

const forkFunctions = [
  fork(watchGetMyAdmissionForms),
  fork(watchGetMyApplicationForm),
  fork(watchGetMyAdmissions),
  fork(watchGetGeneratePaymentReference),
  fork(watchGetDownloadApplicationForm),
  fork(watchGetDownloadAdmissionLetter),
];

export default forkFunctions;
