const unebSubject = {
  GET_UNEB_SUBJECT_REQUEST: 'GET_UNEB_SUBJECT_REQUEST',
  GET_UNEB_SUBJECT_SUCCESS: 'GET_UNEB_SUBJECT_SUCCESS',
  GET_UNEB_SUBJECT_ERROR: 'GET_UNEB_SUBJECT_ERROR',

  GET_UNEB_CENTERS_REQUEST: 'GET_UNEB_CENTERS_REQUEST',
  GET_UNEB_CENTERS_SUCCESS: 'GET_UNEB_CENTERS_SUCCESS',
  GET_UNEB_CENTERS_ERROR: 'GET_UNEB_CENTERS_ERROR',

  getUnebSubjects: (data) => ({
    type: unebSubject.GET_UNEB_SUBJECT_REQUEST,
    data,
  }),

  getUnebCenters: (data) => ({
    type: unebSubject.GET_UNEB_CENTERS_REQUEST,
    data,
  }),
};

export default unebSubject;
