import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { institutionStructureActions } from '../../actions';

function* getAllInstitutionStructure() {
  try {
    const response = yield axios({
      url: '/app/institution-structure/student-portal',
      method: 'GET',
    });
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_STRUCTURE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: institutionStructureActions.GET_INSTITUTION_STRUCTURE_ERROR,
      error: error.data,
    });
  }
}

function* watchGetInstitutionStructure() {
  yield takeLatest(
    institutionStructureActions.GET_INSTITUTION_STRUCTURE_REQUEST,
    getAllInstitutionStructure
  );
}

const forkFunctions = [fork(watchGetInstitutionStructure)];

export default forkFunctions;
