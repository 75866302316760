import { uniqBy } from 'lodash';
import { myApplicationFormActions } from '../../actions';
import initialState from '../../initialState';

function myApplicationForm(state = initialState.myApplicationForm, actions) {
  switch (actions.type) {
    case myApplicationFormActions.GET_MY_ADMISSION_FORMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case myApplicationFormActions.GET_MY_ADMISSION_FORMS_SUCCESS:
      return {
        ...state,
        myApplicationForms: actions.data,
        loadError: {},
        loading: false,
      };
    case myApplicationFormActions.GET_MY_ADMISSION_FORMS_ERROR:
      return {
        ...state,
        loadError: actions.error,
        loading: false,
      };

    case myApplicationFormActions.GET_MY_APPLICATION_FORM_REQUEST:
      return {
        ...state,
        gettingApplicationForm: true,
        selectedFormId: actions.formId,
      };
    case myApplicationFormActions.GET_MY_APPLICATION_FORM_SUCCESS: {
      const { applicationForms } = state;
      return {
        ...state,
        applicationForms: uniqBy(
          [
            { data: actions.data, form_id: actions.formId },
            ...applicationForms,
          ],
          'form_id'
        ),
        applicationFormError: {},
        gettingApplicationForm: false,
      };
    }
    case myApplicationFormActions.GET_MY_APPLICATION_FORM_ERROR:
      return {
        ...state,
        applicationFormError: actions.error,
        gettingApplicationForm: false,
      };

    case myApplicationFormActions.GET_MY_ADMISSIONS_REQUEST:
      return {
        ...state,
        checkingAdmissions: true,
        admissionsError: {},
      };
    case myApplicationFormActions.GET_MY_ADMISSIONS_SUCCESS: {
      const { data } = actions;
      return {
        ...state,
        admissions: data,
        admissionsError: {},
        checkingAdmissions: false,
      };
    }
    case myApplicationFormActions.GET_MY_ADMISSIONS_ERROR:
      return {
        ...state,
        applicationFormError: actions.error,
        checkingAdmissions: false,
      };

    case myApplicationFormActions.GENERATE_PAYMENT_REFERENCE_REQUEST:
      return {
        ...state,
        generatingPaymentReference: true,
        paymentReferenceError: {},
      };
    case myApplicationFormActions.GENERATE_PAYMENT_REFERENCE_SUCCESS:
      return {
        ...state,
        paymentReferenceSuccess: actions.data,
        generatingPaymentReference: false,
      };
    case myApplicationFormActions.GENERATE_PAYMENT_REFERENCE_ERROR:
      return {
        ...state,
        paymentReferenceError: actions.error,
        generatingPaymentReference: false,
      };

    case myApplicationFormActions.DOWNLOAD_APPLICATION_FORM_REQUEST:
      return {
        ...state,
        downloadingForm: true,
        downloadFormId: actions.applicationFormId,
        downloadFormError: {},
      };
    case myApplicationFormActions.DOWNLOAD_APPLICATION_FORM_SUCCESS:
      return {
        ...state,
        downloadingForm: false,
      };
    case myApplicationFormActions.DOWNLOAD_APPLICATION_FORM_ERROR:
      return {
        ...state,
        downloadFormError: actions.error,
        downloadingForm: false,
      };

    case myApplicationFormActions.DOWNLOAD_ADMISSION_LETTER_REQUEST:
      return {
        ...state,
        downloadingAdmissionLetter: true,
        downloadFormId: actions.applicationFormId,
        downloadAdmissionLetterError: {},
      };
    case myApplicationFormActions.DOWNLOAD_ADMISSION_LETTER_SUCCESS:
      return {
        ...state,
        downloadingAdmissionLetter: false,
      };
    case myApplicationFormActions.DOWNLOAD_ADMISSION_LETTER_ERROR:
      return {
        ...state,
        downloadAdmissionLetterError: actions.error,
        downloadingAdmissionLetter: false,
      };

    case myApplicationFormActions.SHOW_PREVIEW_MODAL:
      return {
        ...state,
        showPreviewModal: actions.e,
      };

    case myApplicationFormActions.SHOW_VISA_MODAL:
      return {
        ...state,
        showVisaModal: actions.e,
      };

    case myApplicationFormActions.SET_SELECTED_APPLICATION_FORM:
      return {
        ...state,
        selectedApplicationForm: actions.e,
      };

    default:
      return state;
  }
}

export default myApplicationForm;
