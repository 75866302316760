import { takeLatest, fork, put } from 'redux-saga/effects';
import axios from 'axios';
import { authActions } from '../../actions';
import { clearToken } from '../../services/storageService';

function* registerApplicant(actions) {
  try {
    const response = yield axios({
      url: '/applicants/auth/register',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: authActions.REGISTER_APPLICANT_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.REGISTER_APPLICANT_ERROR,
      error: error.data,
    });
  }
}

function* loginApplicant(actions) {
  try {
    const response = yield axios({
      url: '/applicants/auth/login',
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: authActions.LOGIN_APPLICANT_SUCCESS,
      data: response,
    });
    yield put({
      type: authActions.SET_AUTH_USER,
      user: response.applicant,
    });
  } catch (error) {
    yield put({
      type: authActions.LOGIN_APPLICANT_ERROR,
      error: error.data,
    });
  }
}

function* logoutUser() {
  try {
    const response = yield axios({
      url: '/applicants/auth/logout',
      method: 'POST',
    });
    yield put({
      type: authActions.LOGOUT_SUCCESS,
      data: response,
    });
    clearToken();
    yield put({
      type: authActions.REMOVE_AUTH_USER,
    });
    yield put({
      type: authActions.SET_IS_AUTHENTICATED,
      payload: false,
    });
  } catch (error) {
    if (error.status === 403 || error.status === 401) clearToken();
    yield put({
      type: authActions.LOGOUT_ERROR,
      error: error.data,
    });
  }
}

function* changePassword(action) {
  try {
    const response = yield axios({
      url: '/applicants/auth/change-password',
      method: 'PUT',
      data: action.data,
    });

    yield put({
      type: authActions.CHANGE_PASSWORD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.CHANGE_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* changeDefaultPassword(action) {
  try {
    const response = yield axios({
      url: '/applicants/auth/change-default-password',
      method: 'PUT',
      data: action.data,
    });

    yield put({
      type: authActions.CHANGE_DEFAULT_PASSWORD_SUCCESS,
      data: response,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
  } catch (error) {
    yield put({
      type: authActions.CHANGE_DEFAULT_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* updateProfile(action) {
  try {
    const response = yield axios({
      url: '/applicants/auth/update-profile',
      method: 'PUT',
      data: action.data,
    });

    yield put({
      type: authActions.UPDATE_PROFILE_SUCCESS,
      data: response,
    });
    yield put({
      type: authActions.GET_AUTH_USER_REQUEST,
    });
  } catch (error) {
    yield put({
      type: authActions.UPDATE_PROFILE_ERROR,
      error: error.data,
    });
  }
}

function* requestToken(action) {
  try {
    const response = yield axios({
      url: `/applicants/auth/request-token`,
      method: 'POST',
      data: action.data,
    });
    yield put({
      type: authActions.REQUEST_TOKEN_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.REQUEST_TOKEN_ERROR,
      error: error.data,
    });
  }
}

function* resetPassword(action) {
  try {
    const response = yield axios({
      url: `/applicants/auth/reset-password`,
      method: 'PUT',
      data: action.data,
    });
    yield put({
      type: authActions.RESET_PASSWORD_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.RESET_PASSWORD_ERROR,
      error: error.data,
    });
  }
}

function* fetchAuthUser() {
  try {
    const response = yield axios({
      url: '/applicants/auth/profile',
      method: 'GET',
    });
    yield put({
      type: authActions.GET_AUTH_USER_SUCCESS,
      data: response,
    });
    yield put({
      type: authActions.SET_AUTH_USER,
      user: response.applicant,
    });
  } catch (error) {
    yield put({
      type: authActions.GET_AUTH_USER_ERROR,
      error: error.data,
    });
  }
}

function* requestEmailVerificationLink(action) {
  try {
    const response = yield axios({
      url: `/email/resend-verification-link`,
      method: 'POST',
      data: action.data,
    });
    yield put({
      type: authActions.EMAIL_VERIFICATION_LINK_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.EMAIL_VERIFICATION_LINK_ERROR,
      error: error.data || error,
    });
  }
}

function* verifyEmail(action) {
  try {
    const response = yield axios({
      url: `/email/verify/${action.token}`,
      method: 'POST',
    });
    yield put({
      type: authActions.VERIFY_EMAIL_SUCCESS,
      data: response,
    });
  } catch (error) {
    yield put({
      type: authActions.VERIFY_EMAIL_ERROR,
      error: error.data || error,
    });
  }
}

function* watchRegisterApplicant() {
  yield takeLatest(authActions.REGISTER_APPLICANT_REQUEST, registerApplicant);
}

function* watchLoginApplicant() {
  yield takeLatest(authActions.LOGIN_APPLICANT_REQUEST, loginApplicant);
}

function* watchLogout() {
  yield takeLatest(authActions.LOGOUT_REQUEST, logoutUser);
}

function* watchChangePassword() {
  yield takeLatest(authActions.CHANGE_PASSWORD_REQUEST, changePassword);
}

function* watchChangeDefaultPassword() {
  yield takeLatest(
    authActions.CHANGE_DEFAULT_PASSWORD_REQUEST,
    changeDefaultPassword
  );
}

function* watchUpdateProfile() {
  yield takeLatest(authActions.UPDATE_PROFILE_REQUEST, updateProfile);
}

function* watchRequestToken() {
  yield takeLatest(authActions.REQUEST_TOKEN_REQUEST, requestToken);
}

function* watchResetPassword() {
  yield takeLatest(authActions.RESET_PASSWORD_REQUEST, resetPassword);
}

function* watchFetchAuthUser() {
  yield takeLatest(authActions.GET_AUTH_USER_REQUEST, fetchAuthUser);
}

function* watchRequestEmailVerificationLink() {
  yield takeLatest(
    authActions.EMAIL_VERIFICATION_LINK_REQUEST,
    requestEmailVerificationLink
  );
}

function* watchVerifyEmail() {
  yield takeLatest(authActions.VERIFY_EMAIL_REQUEST, verifyEmail);
}

const forkFunctions = [
  fork(watchRegisterApplicant),
  fork(watchLoginApplicant),
  fork(watchLogout),
  fork(watchChangePassword),
  fork(watchChangeDefaultPassword),
  fork(watchUpdateProfile),
  fork(watchRequestToken),
  fork(watchResetPassword),
  fork(watchFetchAuthUser),
  fork(watchRequestEmailVerificationLink),
  fork(watchVerifyEmail),
];

export default forkFunctions;
