import { all } from 'redux-saga/effects';
import metadata from './app/metadata';
import server from './app/server';
import institutionStructure from './app/institutionStructure';
import auth from './auth';
import runningAdmission from './admission/runningAdmission';
import myApplicationForm from './admission/myApplicationForm';
import applicationSection from './admission/applicationSection';
import unebSubject from './app/unebSubject';

const rootSaga = function* root() {
  yield all([
    ...auth,
    ...metadata,
    ...server,
    ...institutionStructure,
    ...runningAdmission,
    ...applicationSection,
    ...unebSubject,
    ...myApplicationForm,
  ]);
};

export default rootSaga;
